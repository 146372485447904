var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-box', {
    staticClass: "addurls"
  }, [_c('r-section', {
    staticClass: "pb-4",
    attrs: {
      "label": _vm.$t('addUrls.title')
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pt-0 mt-5",
    attrs: {
      "sm": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('addUrls.addURL')))])])], 1), _vm._l(_vm.items, function (item, index) {
    return _c('v-row', {
      key: `addurls-textfield-${index}`,
      staticClass: "addurls--item py-0 my-0"
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "sm": "12"
      }
    }, [_c('r-text-field', {
      staticClass: "addurls--textfield",
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "placeholder": `${_vm.$t('global.abbreviations.example')}: https://amazon.com/dp/ASIN`,
        "value": _vm.items[index].url,
        "rules": _vm.validUrlsExcludingLast ? [] : _vm.urlItem,
        "validate-on-blur": true,
        "error-message": item.serverValidationError
      },
      on: {
        "change": function (value) {
          return _vm.onTextInputChange(index, value);
        }
      }
    }), _vm.items.length > 1 ? _c('v-icon', {
      staticClass: "addurls--delete",
      on: {
        "click": function ($event) {
          return _vm.onClickRemove(index);
        }
      }
    }, [_vm._v(" delete ")]) : _vm._e()], 1)], 1);
  }), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', [_c('r-button', {
    staticClass: "addurls--addmore",
    attrs: {
      "icon": "mdi-plus",
      "label": _vm.$t('global.actions.addMore'),
      "outlined": true,
      "loading": _vm.fetchingValidateUrls,
      "disabled": !_vm.allValid
    },
    on: {
      "click": _vm.onClickAddMore
    }
  })], 1)], 1)], 2), _c('r-section', {
    staticClass: "pb-4",
    attrs: {
      "label": _vm.$t('addUrls.csvUpload.title')
    }
  }, [_c('r-file-upload', {
    staticClass: "addurls--fileupload",
    attrs: {
      "label": _vm.$t('componentLibrary.uploadFileLabel'),
      "placeholder": _vm.$t('componentLibrary.uploadFilePlaceholder'),
      "filetypes": ['.txt', '.csv'],
      "select-file-label": _vm.$t('componentLibrary.uploadFileSelectLabel'),
      "upload-button-label": _vm.$t('componentLibrary.uploadButtonLabel')
    },
    on: {
      "upload": _vm.onFileUpload
    }
  })], 1), _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', [_c('label', [_vm._v(_vm._s(_vm.$t('resources.project')))]), _c('project-select', {
    staticClass: "addurls--selectproject mt-1",
    on: {
      "change": _vm.onChangeSelectedProject
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "sm": "12"
    }
  }, [_vm.fetchCreateUrlsError ? _c('r-error-message', {
    staticClass: "addurls--errormessage",
    attrs: {
      "errors": [_vm.fetchCreateUrlsError]
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "d-flex justify-end align-center"
  }, [_c('r-button', {
    staticClass: "addurls--cancel mr-2",
    attrs: {
      "label": _vm.$t('global.actions.cancel'),
      "outlined": true
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/data');
      }
    }
  }), _c('r-button', {
    staticClass: "addurls--save",
    attrs: {
      "label": _vm.$t('global.actions.save'),
      "disabled": !_vm.validUrlsExcludingLast || _vm.fetchingValidateUrls,
      "loading": _vm.fetchingCreateUrls
    },
    on: {
      "click": _vm.onClickSave
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }