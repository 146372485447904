const includeHttps = (url) => `https://${url.replace(/^https?:\/\//, '')}`

const isValidUrl = (str) => {
  try {
    const url = new URL(includeHttps(str))
    return url.protocol.startsWith('http') && url.hostname.includes('.')
  } catch (_) {
    return false
  }
}

export default isValidUrl
